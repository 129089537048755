<template>
	<v-container class="main-container">
		<p>Back</p>
		<h3 class="admin-default-subheader">Course Name</h3>
		<h2 class="admin-default-header">Assign a quiz to this course</h2>
		<p>Quiz Assigned:</p>
		<label class="admin-default-label" for="new_user_first_name"
			>Search for My Quizes</label
		>
		<v-text-field
			id="new_user_first_name"
			name="new_user_first_name"
			type="text"
			class="rounded-0 admin-default-input"
			solo
			rounded
			single-line
			v-model="newUserFirstName"
		></v-text-field>

		<label
			class="admin-default-label"
			for="organization_settings_home_subheader"
			>Description (Optional)</label
		>
		<v-select
			id="organization_settings_home_subheader"
			class="rounded-0 admin-default-textarea"
			:loading="isLoading"
			v-model="homePageSubheader"
		></v-select>

		<v-btn class="admin-primary-button primary-contrast-background"
			>Assign</v-btn
		>
		OR
		<v-btn class="admin-primary-button primary-contrast-background"
			>Create New Quiz</v-btn
		>
	</v-container>
</template>

<script>
export default {
	name: "NewCourseAssignQuiz"
};
</script>

<style></style>
